import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { useEffect, type ReactNode } from "react";

import cn from "../../util/cn.js";
import VStack from "../Stack/VStack.js";
import Header from "../Typography/Header.js";

interface Props {
  className?: string;
  children?: ReactNode;
  title?: string | null;
}

const centeredCardStyles = cva("w-full min-h-screen sm:min-h-0", {
  variants: {
    size: {
      xs: "sm:max-w-md",
      sm: "sm:max-w-lg",
      md: "sm:max-w-xl",
      lg: "sm:max-w-screen-sm",
      xl: "sm:max-w-screen-lg",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const CenteredCard = ({
  title,
  className,
  children,
  ...variants
}: Props & VariantProps<typeof centeredCardStyles>) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);
  return (
    <div className="flex items-center justify-center sm:mt-8 sm:p-12">
      <div
        className={cn(
          "rounded-lg bg-surface p-8 sm:border sm:shadow-md md:p-8",
          centeredCardStyles(variants),
          className
        )}
      >
        <VStack>
          {title && <Header level={1}>{title}</Header>}
          {children}
        </VStack>
      </div>
    </div>
  );
};

export default CenteredCard;
